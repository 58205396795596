<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">Predict the major product of the following reaction:</p>
      <v-img :src="imageHeaderName" class="mb-3" style="max-width: 500px" />

      <v-radio-group v-model="inputs.input1" class="mb-3 ml-3" :disabled="!allowEditing">
        <v-radio class="my-2" value="a">
          <template #label>
            <v-img :src="image1Name" max-width="250px" />
          </template>
        </v-radio>

        <v-radio class="my-2" value="b">
          <template #label>
            <v-img :src="image2Name" max-width="250px" />
          </template>
        </v-radio>

        <v-radio class="my-2" value="c">
          <template #label>
            <v-img :src="image3Name" max-width="250px" />
          </template>
        </v-radio>

        <v-radio class="my-2" value="d">
          <template #label>
            <v-img :src="image4Name" max-width="250px" />
          </template>
        </v-radio>

        <v-radio class="my-2" value="e">
          <template #label>
            <v-img :src="image5Name" max-width="250px" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-3">Justify your answer by describing the mechanism of the reaction:</p>
      <s-textarea v-model="inputs.input2" outlined />
    </v-form>
  </div>
</template>

<script>
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default {
  name: 'ChemLC241PS10Q3',
  components: {
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
      },
    };
  },
  computed: {
    imageHeaderName() {
      return '/img/assignments/LC241/ps10-q3-header.png';
    },
    image1Name() {
      return '/img/assignments/LC241/ps10-q3-option1.png';
    },
    image2Name() {
      return '/img/assignments/LC241/ps10-q3-option2.png';
    },
    image3Name() {
      return '/img/assignments/LC241/ps10-q3-option3.png';
    },
    image4Name() {
      return '/img/assignments/LC241/ps10-q3-option4.png';
    },
    image5Name() {
      return '/img/assignments/LC241/ps10-q3-option5.png';
    },
  },
};
</script>
